import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styles from "../../styles/SmartPortal.scss";
import { registerIcons, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';


export interface ISmartPortalControlRef {
    _reactControl: JSX.Element;
    _contentList: ISmartPortalControlRef[];

    addContent(control: ISmartPortalControlRef): void;
    render(key?: string): React.ReactElement;
}


export interface IElementWrapperComponentProps {
    element: Element
}

export class ElementWrapperComponent extends React.Component<IElementWrapperComponentProps> {
    private element = this.props.element;
    private wrapperEl: Element;

    public componentDidMount(): void {
        let rootEl: Node;
        if (!this.wrapperEl || !(rootEl = this.wrapperEl.parentNode))
            return;

        if (this.element.parentNode)
            this.element.parentNode.removeChild(this.element);

        rootEl.insertBefore(this.element, this.wrapperEl);
    }

    public componentWillUnmount(): void {
        if (this.element.parentNode)
            this.element.parentNode.removeChild(this.element);
    }

    public render(): React.ReactElement {
        return <script type="text/plain" ref={(wrapperEl) => { this.wrapperEl = wrapperEl; }} />;
    }
}

export class SmartPortalApp {
    private pageContainer: Element;
    private pageControl: React.ReactElement;
    private controlKeyCounter = 0;
    private appReadyPromise: Promise<void>;

    public constructor(appPromise: Promise<void>) {
        this.appReadyPromise = appPromise;

        let container = this.pageContainer = document.createElement("div");
        document.body.insertBefore(container, document.body.firstChild);
        container.className = styles.SmartPortal;
        if (false || !!(document as any).documentMode) {
            container.className += ` ${styles.isIE}`;
        }

         registerIcons({
             fontFace: {
                 fontFamily: "SP365FabricMDL2Icons"
             },
             icons: {
                 'ArchiveUndo': '\uE3A1',
                 'Reset': '\uE423'
             }
         });
        //initializeIcons(undefined, { disableWarnings: true });
        initializeFileTypeIcons();
    }

    public onReady(callback?: () => void): Promise<void> {
        return this.appReadyPromise.then(() => {
            if(callback)
                return callback();
        });
    }

    public loadControl<PropsT extends {}>(type: React.FunctionComponent<PropsT> | React.ComponentClass<PropsT> | string, props: PropsT, content?: ISmartPortalControlRef | ISmartPortalControlRef[]): ISmartPortalControlRef {
        let controlRef: ISmartPortalControlRef = {
            _reactControl: null,
            _contentList: [],
            addContent: (control) => {
                controlRef._contentList.push(control);
            },
            render: (key?: string) => {
                let children = controlRef._contentList.map((childRef, idx) => childRef.render("cid-" + idx.toString()));
                (props as any).key = (key || "") + "#" + (this.controlKeyCounter++).toString();
                return controlRef._reactControl = React.createElement<PropsT>(type, props, children);
            }
        };
        if (content) {
            if (Array.isArray(content))
                content.forEach(controlRef.addContent);
            else
                controlRef.addContent(content);
        }
        return controlRef;
    }

    public getElementControl(element: Element, contentSelector?: string): ISmartPortalControlRef {
        if (element.parentNode)
            element.parentNode.removeChild(element);
        let controlRef: ISmartPortalControlRef = {
            _reactControl: null,
            _contentList: [],
            addContent: (control) => {
                controlRef._contentList.push(control);
            },
            render: (key?: string) => {
                let container = contentSelector ? element.querySelector(contentSelector) : element;
                controlRef._contentList.forEach((childRef) => ReactDOM.render(childRef.render(), container));
                return controlRef._reactControl = React.createElement<IElementWrapperComponentProps>(ElementWrapperComponent, {
                    element: element,
                    key: (key || "") + "#" + (this.controlKeyCounter++).toString()
                });
                
            }
        };
        return controlRef;
    }

    public renderControl(control: ISmartPortalControlRef): void {
        if (this.pageControl) {
            ReactDOM.unmountComponentAtNode(this.pageContainer);
            this.pageContainer.innerHTML = "";
            this.pageControl = null;
        }

        this.pageControl = control.render();
        ReactDOM.render((
            <React.Suspense fallback={this.renderLoadingSplash()}>
                {this.pageControl}
            </React.Suspense>
        ), this.pageContainer);
    }

    private renderLoadingSplash(): React.ReactNode {
        return <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />;
    }

}

